<template>
  <div class="main-container">
    <div class="banner">
      <img src="./../assets/logo-premium.png" class="logo-premium" alt="logo premium" />
    </div>
    <section class="main-section">
      <div class="col-section">
        <div class="text-container">
          <h2>LA TRANSMISSION DE BIENS DE GRANDE ENVERGURE OU EXCEPTIONNELS</h2>
          <p>Les dossiers Premium exigent une expertise spécifique, une démarche adaptée au cas par cas et généralement un cadre de confidentialité très strict. <b>Facil'Immo</b> communique sur ce type de dossiers uniquement avec des acquéreurs potentiels identifiés et qualifiés au préalable notamment concernant la faisabilité financière.</p> 
          <p>Vos interlocuteurs du pôle Premium sont à l’écoute de votre projet de cession ou d’acquisition.</p>
        </div>
      </div>
      <div class="col-section section-premium">
        <div class="gradient"></div>
      </div>
    </section>
    <section class="main-section section-gray section-reverse">
      <div class="col-section">
        <div class="text-container">
          <h2>VOTRE CONTACT DÉDIÉ</h2>
          <div class="contact-container">
              <img src="./../assets/premium-contact.png" alt="premium contact" />
              <div class="premium-contact-infos">
                <h3>Dominique PANIAGUA</h3>
                <h4>Responsable du pôle Premium</h4>
                <p>
                  +33 6 52 56 22 28<br />
                  <a href="mailto:d.paniagua@cabinet-facilimmo.com">d.paniagua@cabinet-facilimmo.com</a>
                </p>
              </div>
          </div>
        </div>
      </div>
      <div class="col-section section-contact">
        <div class="gradient"></div>
      </div>
    </section>
    <section class="contact-section">
      <h2>Contactez-nous</h2>
      <ContactForm></ContactForm>
    </section>
  </div>
</template>

<script>
import ContactForm from './../components/ContactForm.vue'

export default {
  name: 'Premium',
  components: {
    ContactForm
  }

}
</script>

<style lang="scss" scoped>
  .banner {
    background-image: url('./../assets/banner-premium.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .logo-premium {
   width:400px;
    height:auto;
    max-width: 90%;
  }

  .section-premium {
    background-image: url('./../assets/premium-section.jpg');
    background-position: left center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .section-contact {
    background-image: url('./../assets/premium-section-contact.jpg');
    background-position: left center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .text-container h2 {
    color:$secondary-color;
  }

  .premium-contact {
    padding:80px 0;
    background:$bg-secondary;
  }

  .premium-contact h2 {
    color:$main-color;
    text-align: center;
    margin-bottom:40px;
    font-size:30px;
    text-transform: uppercase;
    font-weight:700;
    letter-spacing: 2px;
  }

  .row-premium {
    display: flex;
    justify-content: center;
    align-items: center;
    width:1000px;
    max-width: 90% ;
    margin: 0 auto;
  }

  .premium-contact-img {
    width: 50%;
  }

  .premium-contact-img img {
    width:270px;
    height:auto;
    max-width: 90%;
  }

  .premium-contact-infos {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .premium-contact-infos h3 {
    font-weight:700;
    text-align: center;
    color:$main-color;
    margin: 0;
    font-size:25px;
  }

  .premium-contact-infos h4 {
    font-weight:500;
    text-align: center;
    color:$secondary-color;
    margin: 0;
    font-size:20px;
  }

  .premium-contact-infos p {
    font-weight:400;
    text-align: center;
    color:$text-color;
    margin-top: 30px ;
    font-size:20px;
  }

  .premium-contact-infos p a {
    text-decoration: none;
    color:$text-color;
  }

  .premium-contact-infos p a:hover {
    text-decoration: underline;
  }

  .contact-section {
    padding:80px 0;
    background-image: url('./../assets/bg-contact-form.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

.contact-section h2 {
    text-align: center;
    color:#fff;
    font-size:35px;
    font-weight:500;
    margin:0;
    text-transform: uppercase;
    letter-spacing: 2px;
  }

.contact-container {
  display: flex;
  align-items: center;
  margin:40px 0;
}

.contact-container img {
  width:200px;
  height:auto;
  margin-right:20px;
}
@media screen and (max-width:768px) {
  .contact-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .contact-container img {
    margin : 0 0 20px 0;
  }

  .premium-contact-infos {
    width:100%;
  }
}
</style>